import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`;

	return (
		<Layout location={location} title={siteTitle}>
			<Seo title="GreenStudio" />
			<StaticImage className="home-top-image" src="../images/topImage.png" quality={80} alt="トップ画像" />
			<div className="home-discription1">
				グリーンスタジオは広報・宣伝のデザイン制作会社です。
				<br />
				みなさんの想いをより分かりやすく、明日に伝わる種まきをお手伝いしています。
			</div>
			<div className="home-works-gridstyle">
				<Link className="home-works-link" to="/works#category1" state={{ category: "地域" }}>
					<StaticImage className="home-image" src="../images/category1.jpg" quality={80} alt="地域" />
					<div className="home-image-title">地域</div>
				</Link>
				<Link className="home-works-link" to="/works#category2" state={{ category: "福祉" }}>
					<StaticImage className="home-image" src="../images/category2.jpg" quality={80} alt="福祉" />
					<div className="home-image-title">福祉</div>
				</Link>
				<Link className="home-works-link" to="/works#category3" state={{ category: "子育て" }}>
					<StaticImage className="home-image" src="../images/category3.jpg" quality={80} alt="子育て" />
					<div className="home-image-title">子育て</div>
				</Link>
				<Link className="home-works-link" to="/works#category4" state={{ category: "行政" }}>
					<StaticImage className="home-image" src="../images/category4.jpg" quality={80} alt="行政" />
					<div className="home-image-title">行政</div>
				</Link>
				<Link className="home-works-link" to="/works#category5" state={{ category: "ものづくり" }}>
					<StaticImage className="home-image" src="../images/category5.jpg" quality={80} alt="ものづくり" />
					<div className="home-image-title">ものづくり</div>
				</Link>
				<Link className="home-works-link" to="/works#category6" state={{ category: "other" }}>
					<StaticImage className="home-image" src="../images/category6.jpg" quality={80} alt="other" />
					<div className="home-image-title">other</div>
				</Link>
			</div>
			<div className="home-discription1">業務の一例から私たちの取組みや考え方をご説明しています。</div>
			{/*<div className="home-discription2">イベントがつなぐ人と人。まちの活力を高める取組に参画しています。</div>*/}
		</Layout>
	);
};

export default BlogIndex;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					description
				}
			}
		}
	}
`;
